a {
  color: $c-link;
  text-decoration: none;
  @include transition();

  i:before {
    @include transition();
  }

  &:hover {
    color: $c-link-hover;

    &:before,
    i:before {
      color: $c-link-hover;
    }
  }
}
