.modal {
  &-dialog {
    max-width: 800px;
  }

  &-content {
    padding: 0 40px 40px 40px;
  }

  &-header {
    padding: 0;
    margin-bottom: 30px;
  }

  &-body {
    padding: 0;
  }

  &-close {
    background: transparent;
    border: none;
    font-size: rem-size(18px);
    position: absolute;
    top: 20px;
    right: 40px;
    cursor: pointer;

    &:before {
      @include fi('close');
      color: #000;
    }

    &:hover {
      &:before {
        color: $c-1;
      }
    }
  }

  &-title {
    font-size: rem-size(32px);
    color: #000;
    padding: 40px 0 30px 0;
  }
}
