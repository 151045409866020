/**
  * Created by IRIS Interactive
  * User : IRIS Interactive
*/

/* Init
/* ============================================= */
*,
*:after,
*:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: rgba($c-2, 0.3);
  color: $c-text;
  font-family: $f-text;
  font-size: rem-size($font-size);
  line-height: $line-height;
}

/* Layout
/* ============================================= */
.wrapper {
  display: grid;
  grid-template-columns: $sidebar 1fr;
  grid-template-rows: $header-height 1fr;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;

  @include breakpoint(medium) {
    grid-template-columns: $header-height 1fr;
  }
}

.header {
  grid-column: span 2;
  grid-row: 1;
}

.sidebar {
  grid-column: 1;
}

.main {
  grid-column: 2;
  overflow: scroll;
  /*    padding: 50px 0;*/
}

.container {
  max-width: $container;
  margin: 0 auto;
  position: relative;

  @include breakpoint(large) {
    margin: 0 viewport-border(large);
  }

  @include breakpoint(medium) {
    margin: 0 viewport-border(medium);
  }

  @include breakpoint(small) {
    margin: 0 viewport-border(small);
  }

  @include breakpoint(xsmall) {
    margin: 0 viewport-border(xsmall);
  }
}

.article {
  max-width: $article;
  margin: 0 auto;
  position: relative;
}
