.section {
  padding: 20px 0;
  border-bottom: 1px solid $c-border;

  &__heading {
    display: flex;

    @include breakpoint(large) {
      flex-wrap: wrap;
    }

    &__title {
      margin-right: 20px;

      &__name {
        font-size: rem-size(32px);
        font-weight: 800;
        color: #000;
        line-height: 1.2;

        a {
          display: block;
          color: #000;
          background-color: transparent;
          width: auto;
          height: auto;

          &:before {
            display: none;
          }
        }
      }
    }

    &__tools {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include breakpoint(xlarge) {
        flex-shrink: 0;
      }

      @include breakpoint(large) {
        flex-wrap: wrap;
      }

      a {
        font-size: rem-size(14px);
        margin-left: 20px;
      }

      &__toggle {
        @include align(center, center);
        width: 40px;
        height: 40px;
        border-radius: 6px;
        background-color: $c-2;
        color: $c-1;

        &:before {
          font-size: rem-size(11px);
          @include transition();
        }

        &[aria-expanded='true'] {
          &:before {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__profil {
    display: flex;
    align-items: center;

    &__thumbnail {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
    }

    &__name {
      color: #000;
      font-size: rem-size(32px);
    }

    &__link {
      margin-left: auto;
    }
  }

  &__content {
    @include grid-item(2, $gutter);
    margin-top: 30px;
  }
}
