/* Tooltip
/* ============================================= */
.tooltip-inner {
  color: #fff;
  background-color: $c-1 !important;
  padding: 10px 15px !important;
  font-size: rem-size(12px);
  border-radius: 5px;

  span {
    white-space: nowrap;
  }
}

$placements: ('top', 'bottom', 'left', 'right');

@each $placement in $placements {
  .tooltip.bs-tooltip-auto[x-placement^='#{$placement}'] .arrow::before,
  .tooltip.bs-tooltip-#{$placement} .arrow::before {
    border-#{$placement}-color: $c-1 !important;
  }
}

/* Popover
/* ============================================= */
.popover {
  background-color: $c-1 !important;
  border-radius: 5px;
  border: none;
  padding: 10px 15px;

  &-header {
    font-size: rem-size(12px);
    color: #fff;
    padding: 0;
  }

  &-body {
    font-size: rem-size(12px);
    color: #fff;
    padding: 0;
  }
}

$placements: ('top', 'bottom', 'left', 'right');

@each $placement in $placements {
  .popover.bs-popover-auto[x-placement^='#{$placement}'] .arrow::before,
  .popover.bs-popover-auto[x-placement^='#{$placement}'] .arrow::after,
  .popover.bs-popover-#{$placement} .popover-arrow:before,
  .popover.bs-popover-#{$placement} .popover-arrow:after {
    border-#{$placement}-color: $c-1 !important;
  }
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^='bottom'] > .popover-arrow {
  top: -8px !important;
}
