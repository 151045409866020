/**
  * Created by IRIS Interactive
  * User : IRIS Interactive
*/

/* Header
/* ============================================= */
.header {
  z-index: 900;
  background: #fff;
  height: $header-height;
  padding: 0 40px;
  display: flex;
  align-items: center;

  // Logo
  &__logo {
    padding: 25px 0;
    height: 100%;
    width: ceil($sidebar - 40px);

    &__item {
      img {
        height: 100%;
      }
    }
  }

  // Infos
  &__infos {
    &__name {
      font-size: rem-size(14px);
    }

    &__date {
      font-weight: 800;
      color: #000;
    }
  }

  // Tools
  &__tools {
    list-style-type: none;
    margin-left: auto;
    @include align(center, center);

    &__link {
      font-size: rem-size(14px);
      margin-right: 30px;

      @include breakpoint(medium) {
        margin-right: 15px;

        span {
          display: none;
        }

        i {
          font-size: rem-size(20px);
        }
      }
    }

    &__profil {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      overflow: hidden;
      display: block;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }

      &__link {
        list-style-type: none;

        &__item {
          margin: 5px 0;
        }

        a {
          color: #fff;
          font-weight: 800;

          &:hover {
            color: $c-link-hover;
          }
        }
      }
    }
  }
}
