.sidebar {
  z-index: 900;
  background: $c-2;
  padding: 40px 20px;

  @include breakpoint(medium) {
    padding: 40px 10px;
  }
}

.menu {
  &__list {
    list-style-type: none;
  }

  &__item {
    font-size: rem-size(13px);

    i {
      font-size: rem-size(18px);
      font-weight: normal;
      margin-right: 10px;
    }

    a {
      font-weight: 800;
      color: rgba(#000, 0.3);
      position: relative;
      width: 100%;
      padding: 10px 0;

      @include breakpoint(medium) {
        justify-content: center;
        flex-direction: column;

        i {
          margin-right: 0;
        }

        span {
          font-size: rem-size(10px);
          line-height: 1.2;
          margin-top: 4px;
          text-align: center;
        }
      }

      &:after {
        content: '';
        position: absolute;
        right: -22px;
        top: 0;
        display: block;
        height: 100%;
        width: 6px;
        border-radius: 6px;
        background-color: $c-1;
        opacity: 0;
        @include transition();

        @include breakpoint(medium) {
          width: 4px;
          border-radius: 4px;
          right: -12px;
        }
      }

      &.active {
        color: #000;

        &:after {
          opacity: 1;
        }
      }

      &:hover {
        color: #000;
      }
    }
  }
}
