.counter {
  $width: 5%;
  position: relative;

  &__infos {
    line-height: 1;
    position: absolute;
    height: calc(100% - calc(#{$width} * 2));
    width: calc(100% - calc(#{$width} * 2));
    background: #fff;
    border-radius: 150px;
    top: $width;
    left: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    right: 0;
    margin: auto;
    font-size: rem-size(12px);

    &__min {
      font-weight: 900;
      font-size: rem-size(18px);
      color: #000;
    }
  }
}
