.temperature-slider {
  height: 80px;
  background: $c-1;
  border-radius: 15px;
  width: 100%;
  overflow: hidden;
  background-image: url('../../images/background-temperature.jpg');

  &__item {
    font-weight: 800;
    color: #fff;
    font-size: rem-size(38px);
    opacity: 0.5;
    cursor: pointer;
    @include align(center, center);
    transition: opacity 0.3 ease;

    sup {
      opacity: 0;
      font-size: rem-size(30px);
      position: relative;
      top: -0.5em;
      @include transition();
    }

    &.swiper-slide-active {
      opacity: 1;
      font-size: rem-size(45px);

      sup {
        opacity: 1;
      }
    }
  }
}
