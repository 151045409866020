.highlight-info {
  font-size: rem-size(16px);
  line-height: 1.2;
  display: flex;
  align-items: center;

  &:before {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    margin-right: 15px;
    border-radius: 50%;
    color: #fff;
    font-weight: 400;
    font-size: rem-size(20px);
    background-color: $c-border;
    @include align(center, center);
  }

  @each $label, $color in $highlight-colors {
    &--#{$label}:before {
      background-color: $color;
    }
  }
}

.highlight-info-legend {
  display: flex;
  align-items: center;

  &:before {
    content: '';
    width: 20px;
    height: 10px;
    flex: 0 0 20px;
    margin-right: 10px;
    border-radius: 2px;
    background-color: $c-border;
  }

  @each $label, $color in $highlight-colors {
    &--#{$label}:before {
      background-color: $color;
    }
  }
}
