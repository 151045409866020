table {
  width: 100%;
  border-collapse: collapse;
  font-size: rem-size(16px);

  a {
    font-size: rem-size(14px);
  }
}

thead {
  width: 100%;
  font-size: rem-size(13px);
  color: $c-3;

  &.has-border {
    border-bottom: solid 1px $c-border;

    th {
      padding-bottom: 8px;
    }
  }
}

td,
th {
  padding: 15px 0;
  border: none;
  text-align: left;
  min-width: 200px;
}

th {
  text-align: left;
  display: table-cell !important;
  padding: 0;
}

tbody tr {
  border-bottom: 1px solid $c-border;

  &:last-child {
    border-bottom: none;
  }
}
