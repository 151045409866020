.panel {
  background: #fff;
  border-radius: 15px;
  padding: 35px 30px 0;
  @include grid-item(2, 0);
  @include boxshadow();
  grid-column: span 2;

  &--half {
    grid-column: span 1;
  }

  &__item {
    padding-left: 40px;
    margin-bottom: 20px;
    width: 100%;
    grid-column: span 2;

    &--noicon {
      padding-left: 0;
    }

    &--full {
      grid-column: span 2;
    }

    &--half {
      grid-column: span 1;
    }

    &--single {
      padding: 0;
    }

    &--alert {
      margin-bottom: 5px;

      .panel__item__heading {
        display: block;
        padding-bottom: 0;

        &:after {
          display: none;
        }
      }

      .panel__item__heading__tools > p {
        margin-left: 0;
      }
    }

    &--has-border {
      padding-bottom: 20px;
      margin-bottom: 30px;
      border-bottom: solid 1px $c-border;
    }

    &__heading {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      position: relative;
      flex: 2 0 50%;

      thead & {
        padding-bottom: 0;
      }

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: $c-border;
        position: absolute;
        left: 0;
        bottom: 0;

        .panel__item--half & {
          width: calc(100% + 40px);
          left: -40px;
        }

        .noborder &,
        th & {
          display: none;
        }
      }

      &:before {
        position: absolute;
        left: -40px;
        top: 4px;
        color: $c-1;
        font-size: rem-size(20px);
        line-height: 1;
      }

      &--no-border {
        &:after {
          display: none;
        }
      }

      &__title {
        &__name {
          font-weight: 900;
          color: #000;
          font-size: rem-size(18px);
        }

        &__metas {
          font-size: rem-size(12px);

          span {
            font-weight: 800;
            color: #000;
          }
        }
      }

      &__tools {
        margin-left: auto;
        display: flex;

        a:not(.button) {
          font-size: rem-size(14px);
          margin-left: 20px;
          font-weight: 400;
        }

        .flexalign strong {
          margin: 0 5px;
        }

        > * {
          margin-left: 10px;
        }
      }
    }

    &__content {
      &__title {
        font-weight: 800;
        margin-bottom: 5px;
        color: $c-3;
      }
    }

    &__highlight {
      @include grid-item(3, $gutter);
    }

    &__counter {
      display: flex;

      .counter {
        width: 100px;
        height: 100px;
      }

      .panel__item__heading {
        padding: 0;
        margin-left: 60px;

        &:before {
          top: 50%;
          transform: translateY(-50%);
        }

        &:after {
          display: none;
        }

        &__tools {
          flex-direction: column;
        }
      }
    }

    &__toolbar {
      display: flex;
      justify-content: space-between;

      .form-select {
        display: inline-block;
      }
    }

    &__data {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0;

      &__icon {
        font-size: rem-size(80px);
        margin-right: $gutter;
        color: $c-1;
        height: 65px;
        display: flex;
        align-items: center;
      }

      &__content {
        width: 50%;
      }

      &__tool {
        margin-left: $gutter;
      }
    }

    &__table {
      width: 100%;

      &__toggle {
        list-style-type: none;
        border-bottom: 1px solid $c-border;

        li {
          margin-right: 20px;
          display: inline-block;
        }

        a {
          font-size: rem-size(13px);
          color: #000;
          opacity: 0.3;
          font-weight: 600;
          position: relative;
          display: block;
          padding-bottom: 8px;

          &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 3px;
            background-color: $c-1;
            border-radius: 3px;
            opacity: 0;
          }

          &.active {
            opacity: 1;

            &:after {
              opacity: 1;
            }
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
