input:not([type='range']),
select,
textarea {
  appearance: none;
  text-align: left;
  border: none;
  padding: 10px 15px;
  background-color: $c-2;
  border-radius: 6px;
  display: block;
  font-size: rem-size(12px);
  line-height: 1.2;
  color: $c-text;
  border: 2px solid $c-2;
  @include transition();

  &:focus {
    outline: 0 !important;
    border-color: $c-1;
    background: #fff;
  }

  &.error {
    border-color: $c-6;
    background: #fff;
  }
}

.form-select.error {
  border: 2px solid $c-6;
  border-radius: 6px;
}

input::-webkit-input-placeholder {
  color: $c-text;
  opacity: 1;
}

input:-moz-placeholder {
  color: $c-text;
  opacity: 1;
}

input::-moz-placeholder {
  color: $c-text;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: $c-text;
  opacity: 1;
}

/* Sample field
/* ============================================= */
[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
[type='button'],
[type='file'],
select:not([multiple]),
button {
  max-width: 100%;
  width: 100%;
  height: 40px;
}

select[multiple] {
  max-width: 100%;
  width: 100%;
}

[type='button'],
button {
  width: auto;
}

input[type='date'],
input[type='time'] {
  font-family: $f-text;
}

/* Textarea
/* ============================================= */
textarea {
  height: 300px;
  line-height: $line-height;
  font-size: rem-size(12px);
  resize: none;
  width: 100%;
  padding: 20px;
  color: $c-text;
  font-family: $f-text;
  border-radius: 6px;
}

textarea::-webkit-input-placeholder {
  color: $c-text;
  opacity: 1;
}

textarea:-moz-placeholder {
  color: $c-text;
  opacity: 1;
}

textarea::-moz-placeholder {
  color: $c-text;
  opacity: 1;
}

textarea:-ms-input-placeholder {
  color: $c-text;
  opacity: 1;
}

/* Range
/* ============================================= */
.range-affluence {
  position: relative;
  height: 40px;
  width: 100%;

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    font-size: rem-size(20px);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-image: linear-gradient(90deg, $c-4 0%, $c-5 50%, $c-6 100%);
    background-size: 100% 100%;
    pointer-events: none;
    z-index: 0;
    pointer-events: none;
  }

  &:before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 18px;
    left: 0;
    transform: translateX(-15px);
    width: 60px;
    height: 60px;
    background-image: url('../../images/range-cursor.svg');
    background-size: cover;
    pointer-events: none;
  }

  &[data-current-value='1'] {
    &:before {
      left: 1.5%;
    }

    &:after {
      width: 20%;
      background-image: linear-gradient(90deg, $c-4 0%, $c-5 250%);
    }

    i:first-child:before {
      color: #fff;
    }
  }

  &[data-current-value='2'] {
    &:before {
      left: 45%;
    }

    &:after {
      width: 70%;
      background-image: linear-gradient(90deg, $c-4 0%, $c-5 100%);
    }

    i:first-child:before,
    i:nth-child(2):before {
      color: #fff;
    }
  }

  &[data-current-value='3'] {
    &:before {
      left: 90%;
    }

    &:after {
      width: 100%;
      background-image: linear-gradient(90deg, $c-4 0%, $c-5 50%, $c-6 100%);
    }

    i:before {
      color: #fff;
    }
  }

  input {
    -webkit-appearance: none;
    margin-right: 15px;
    width: 100%;
    height: 40px;
    border-radius: 25px;
    background-color: $c-2;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    outline: 0 !important;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 200%;
      width: 40px;
      background: transparent;
    }

    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }
  }
}

/* Select
/* ============================================= */
.form-select {
  position: relative;

  select {
    padding-right: 35px;
  }

  &:before {
    @include fi(chevron-bottom);
    font-size: rem-size(12px);
    position: absolute;
    display: block;
    pointer-events: none;
    color: $c-1;
    top: 12px;
    right: 10px;
    z-index: 1;
  }
}

/* Checkbox
/* ============================================= */

// Base for label styling
[type='checkbox']:not(:checked),
[type='checkbox']:checked {
  position: absolute;
  left: -9999px;
}

[type='checkbox']:not(:checked) + label,
[type='checkbox']:checked + label {
  cursor: pointer;
  padding-left: 35px;
  position: relative;
  display: block;
}

// Checkbox aspect
[type='checkbox']:not(:checked) + label:before,
[type='checkbox']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 25px;
  height: 25px;
  border: none;
  background: #000;
  border-radius: 0;
}

// Checked mark aspect
[type='checkbox']:not(:checked) + label:after,
[type='checkbox']:checked + label:after {
  @include fi('check');
  position: absolute;
  left: 0;
  top: 50%;
  width: 25px;
  text-align: center;
  color: #fff;
  @include transition;
}

// Checked mark aspect changes
[type='checkbox']:not(:checked) + label:after {
  opacity: 0;
  transform: translate(0, -50%) scale(0);
}

[type='checkbox']:checked + label:after {
  opacity: 1;
  transform: translate(0, -50%) scale(1);
}

/* Radio
/* ============================================= */
// Base for label styling
[type='radio']:not(:checked),
[type='radio']:checked {
  position: absolute;
  left: -9999px;
}

[type='radio']:not(:checked) + label,
[type='radio']:checked + label {
  cursor: pointer;
  padding-left: 35px;
  position: relative;
  display: block;
}

// Radio aspect
[type='radio']:not(:checked) + label:before,
[type='radio']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 25px;
  height: 25px;
  border: none;
  background: #000;
  border-radius: 50%;
}

// Checked mark aspect
[type='radio']:not(:checked) + label:after,
[type='radio']:checked + label:after {
  @include fi('check');
  position: absolute;
  left: 0;
  top: 50%;
  width: 25px;
  text-align: center;
  color: #fff;
  @include transition;
}

// Checked mark aspect changes
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  transform: translate(0, -50%) scale(0);
}

[type='radio']:checked + label:after {
  opacity: 1;
  transform: translate(0, -50%) scale(1);
}

/* Form part
/* ============================================= */
.form__part {
  @include grid-item(2, 0);
  grid-column-gap: $gutter;
  margin-bottom: 20px;

  &__title {
    font-weight: 800;
    color: #000;
    grid-column: span 2;
  }
}

.form__group {
  grid-column: span 2;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  &--half {
    grid-column: span 1;
  }

  label {
    font-weight: bold;
    color: $c-3;
    font-size: rem-size(14px);
    margin-bottom: 5px;
    display: block;
  }

  ion-text {
    font-size: 0.75rem;
  }
}
