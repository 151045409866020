.heading {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid $c-border;

  &__title {
    font-size: rem-size(22px);
    font-weight: 400;
    margin: 0;

    i {
      margin-right: 20px;
      font-size: rem-size(40px);
    }
  }

  &__tools {
    margin-left: auto;
    display: flex;

    > *:not(:first-child) {
      margin-left: 20px;
    }
  }
}
