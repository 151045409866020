.infobox {
  font-size: rem-size(12px);
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: $c-2;
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 10px;

  &:before {
    margin-right: 5px;
  }
}
