$icon-family: 'theme-font';

// Fonts file
@font-face {
  font-family: $icon-family;
  font-weight: normal;
  font-style: normal;
  src: url('../../fonts/svgfont/theme-font.eot'),
    url('../../fonts/svgfont/theme-font.woff') format('woff'),
    url('../../fonts/svgfont/theme-font.ttf') format('truetype'),
    url('../../fonts/svgfont/theme-font.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/svgfont/theme-font.svg#theme-font') format('svg');
  font-display: swap;
}

// Array fonts to mixin
$icons: (
  'add': '\ea01',
  'alert': '\ea02',
  'anchor': '\ea03',
  'arrow-bottom': '\ea04',
  'arrow-left': '\ea05',
  'arrow-right': '\ea06',
  'arrow-top': '\ea07',
  'average': '\ea08',
  'calendar': '\ea09',
  'check': '\ea0a',
  'chevron-bottom': '\ea0b',
  'chevron-left': '\ea0c',
  'chevron-right': '\ea0d',
  'chevron-top': '\ea0e',
  'close': '\ea0f',
  'contact': '\ea10',
  'dashboard': '\ea11',
  'day-cloudy': '\ea12',
  'day-rain': '\ea13',
  'day-sleet': '\ea14',
  'day-snow': '\ea15',
  'day-sunny': '\ea16',
  'doc': '\ea17',
  'download': '\ea18',
  'edit': '\ea19',
  'export': '\ea1a',
  'flag': '\ea1b',
  'geoloc': '\ea1c',
  'group': '\ea1d',
  'heart': '\ea1e',
  'high': '\ea1f',
  'infos': '\ea20',
  'link': '\ea21',
  'low': '\ea22',
  'marker-user': '\ea23',
  'marker': '\ea24',
  'mobile-input': '\ea25',
  'mobile-output': '\ea26',
  'monitoring': '\ea27',
  'period': '\ea28',
  'phone': '\ea29',
  'profil': '\ea2a',
  'quote': '\ea2b',
  'rescue': '\ea2c',
  'search': '\ea2d',
  'subscription': '\ea2e',
  'sun': '\ea2f',
  'temp': '\ea30',
  'time': '\ea31',
  'trash': '\ea32',
  'water-quality': '\ea33',
  'water-temperature': '\ea34',
  'wave': '\ea35',
  'webcam': '\ea36',
  'widgets': '\ea37',
  'wind': '\ea38',
);

// Fonts class
.fi:before {
  font-family: $icon-family;
  font-style: initial;
  color: $c-icon;
}

@each $name, $icon in $icons {
  .fi-#{$name}:before {
    content: $icon;
  }
}
