.ng-arrow-wrapper {
  display: none;
}

.form-select {
  &--ng-select {
    border: 0 !important;
  }
}

.ng-select .ng-select-container {
  background-color: $c-2;
  border: 0;
  border-radius: 6px;
  border: 2px solid $c-2;
}

.ng-select.ng-select-opened {
  border-color: $c-1;
}

.form-select.error {
  .ng-select-container {
    border-color: #d12626;
  }
}

.ng-select.ng-select-opened > .ng-select-container {
  background-color: transparent;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: transparent !important;
  box-shadow: none !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 40px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0 !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding: 5.5px;
  gap: 8px;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding: 0;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  margin-bottom: 0;
  margin-right: 0;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  height: 25px;
}
