.entry-data {
  color: $c-1;
  font-weight: 400;

  &--big {
    font-size: rem-size(30px);
    font-weight: 700;
  }
}

.chart {
  grid-column: span 2;
}

.chart__legend {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 4px 20px;
  margin: 16px;
  font-size: 80%;
}
