/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
/*@import "~@ionic/angular/css/normalize.css";*/
@import '~@ionic/angular/css/structure.css';
/*@import "~@ionic/angular/css/typography.css";*/
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import './assets/scss/app';

ion-router-outlet {
  position: relative;
  contain: inherit;

  .ion-page {
    z-index: 101;
    overflow: scroll;
    padding: 50px 0;
    background: rgb(245, 249, 250);
    display: block;
    contain: inherit;
    position: relative;
    height: 100%;
  }
}

ion-popover {
  --background: #{$c-1};
  --box-shadow: none;
  --width: 'auto';
  --offset-y: 10px;

  .popover-content {
    left: unset !important;
    right: 0 !important;
  }

  .my_popover {
    padding: 10px 15px;
    font-size: 0.75rem;
  }
}

ion-spinner {
  --color: #{$c-1};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 80px;
  aspect-ratio: 1;
  position: absolute;
  width: 80px;
}

ion-modal.mp-modal {
  --background: none;
  --max-width: 800px;
  --min-width: 800px;
  --height: 70%;

  ion-content.mp-modal-content {
    --background: none;
    --min-width: auto;
    --max-height: 100%;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;

    .modal-content {
      border: none;
      height: 100%;
    }

    .modal-body {
      padding-bottom: 40px;
    }
  }
}

ion-modal.expanded {
  --max-width: 70%;
  --min-width: 70%;
  --height: 90%;
}

ion-modal.collapsed {
  --height: 43%;
}

ion-loading.my-loading {
  --spinner-color: #{$c-1};
  --background: none;

  .loading-wrapper {
    box-shadow: none;
  }
}
